import Vue from 'vue';
import Vuex from 'vuex';
import commodityStore from './commodity.store';
import configurationStore from './configuration.store';
import customerStore from './customer.store';
import orderStore from './order.store';
import productionStore from './production.store';
import statisticsStore from './statistics.store';
import storageStore from './storage.store';
import terminalStore from './terminal.store';

import { IRootState } from '@/store/rootstate';
import { ICommodityModule } from './commodity.store';
import { IConfigurationModule } from './configuration.store';
import { ICustomerModule } from './customer.store';
import { IOrderModule } from './order.store';
import { IProductionModule } from './production.store';
import { IStorageModule } from './storage.store';
import { ITerminalModule } from './terminal.store';
import { IStatisticsModule } from './statistics.store';

Vue.use(Vuex);

const moduleNames = {
  commodity: 'commodity',
  configuration: 'configuration',
  customer: 'customer',
  order: 'order',
  production: 'production',
  storage: 'storage',
  terminal: 'terminal',
  statistics: 'statistics',
};

const store = new Vuex.Store<IRootState>({
  state: {
    commodity: {} as ICommodityModule,
    configuration: {} as IConfigurationModule,
    customer: {} as ICustomerModule,
    order: {} as IOrderModule,
    production: {} as IProductionModule,
    storage: {} as IStorageModule,
    terminal: {} as ITerminalModule,
    statistics: {} as IStatisticsModule,
  },
  mutations: {},
  actions: {},
});
store.registerModule(moduleNames.commodity, commodityStore);
store.registerModule(moduleNames.configuration, configurationStore);
store.registerModule(moduleNames.customer, customerStore);
store.registerModule(moduleNames.order, orderStore);
store.registerModule(moduleNames.production, productionStore);
store.registerModule(moduleNames.storage, storageStore);
store.registerModule(moduleNames.terminal, terminalStore);
store.registerModule(moduleNames.statistics, statisticsStore);
export default store;

export { moduleNames };
