import { Module } from 'vuex';
import { ShiftReadiness, ProdOperation } from '@/models/operation.model';
import { ProductionService } from '@/services/production.service';
import { HttpService } from '@/services/http.service';
import { StorageService } from '@/services/storage.service';
import { Order } from '@/models/order.model';
import { ITerminalModule } from './terminal.store';
import { IRootState } from '@/store/rootstate';

export interface IProductionModule {
  dateReadiness: ShiftReadiness[];
  filter: string;
  listingDate: string;
  operations: ProdOperation[];
  operationsFiltered: ProdOperation[];
  page: number;
  pages: number;
  pageSize: number;
  productionLoading: boolean;
  selectedOperation: ProdOperation;
  sortBySubject: boolean;
}
const productionMutations = {
  setFilter: 'setFilter',
  setDateReadiness: 'setDateReadiness',
  setListingDate: 'setListingDate',
  setOperations: 'setOperations',
  setPage: 'setPage',
  setProductionLoading: 'setProductionLoading',
  setSelectedOperation: 'setSelectedOperation',
  setSortBySubject: 'setSortBySubject',
};
const productionActions = {
  changeFilter: 'changeFilter',
  changePage: 'changePage',
  changeSorting: 'changeSorting',
  checkSelectedOperation: 'checkSelectedOperation',
  fetchOperations: 'fetchOperations',
  finishOperation: 'finishOperation',
  reserveOperation: 'reserveOperation',
  selectOperation: 'selectOperation',
};

const productionService = new ProductionService();
const storageService = new StorageService();
const productionModule: Module<IProductionModule, IRootState> = {
  state: {
    dateReadiness: [],
    filter: '',
    listingDate: productionService.GetSelectedDate(),
    operations: [],
    operationsFiltered: [],
    page: 1,
    pages: 1,
    pageSize: 6,
    productionLoading: false,
    selectedOperation: {} as ProdOperation,
    sortBySubject: false,
  },
  getters: {
    hasTerminalImport(state, getters, rootState) {
      const user = (rootState.terminal as ITerminalModule).user;
      return (
        user.AuthorityId.includes('Administration') ||
        user.AuthorityId.includes('WarehouseManagement') ||
        user.Flags.findIndex((v) => v.includes('Import')) != -1
      );
    },
  },
  mutations: {
    [productionMutations.setDateReadiness](
      state,
      dateReadiness: ShiftReadiness[]
    ) {
      state.dateReadiness = dateReadiness;
    },
    [productionMutations.setFilter](state, filter: string) {
      state.filter = filter;
      state.operationsFiltered = state.operations.filter((op) =>
        !state.filter
          ? true
          : op.Note.toLocaleLowerCase().includes(
              state.filter.toLocaleLowerCase()
            )
      );
      state.pages = Math.max(
        Math.ceil(state.operationsFiltered.length / state.pageSize),
        1
      );
      if (state.page > state.pages) {
        state.page = 1;
      }
    },
    [productionMutations.setListingDate](state, listingDate: string) {
      state.listingDate = listingDate;
      state.page = 1;
    },
    [productionMutations.setOperations](
      state,
      payload: { operations: ProdOperation[]; hasImport: boolean }
    ) {
      state.operations = payload.operations.sort((a, b) => {
        return -(
          b.OperationNumber -
          a.OperationNumber +
          (HttpService.isTerminalId(a.SelectedTerminalId)
            ? HttpService.isTerminalId(b.SelectedTerminalId)
              ? 0
              : 1
            : HttpService.isTerminalId(b.SelectedTerminalId)
            ? -1
            : 0) *
            10000 +
          (a.Blocked ? (b.Blocked ? 0 : -1) : b.Blocked ? 1 : 0) * 100000 +
          (a.Done ? (b.Done ? 0 : -1) : b.Done ? 1 : 0) * 1000000
        );
      });
      if (!payload.hasImport) {
        state.operations = state.operations.filter(
          (op) => !(op.Type === 'ProdPort' && op.PortType === 'Import')
        );
      }
      state.operationsFiltered = state.operations.filter((op) =>
        !state.filter
          ? true
          : op.Note.toLocaleLowerCase().includes(
              state.filter.toLocaleLowerCase()
            )
      );
      state.pages = Math.max(
        Math.ceil(state.operationsFiltered.length / state.pageSize),
        1
      );
      if (state.page > state.pages) {
        state.page = 1;
      }
    },
    [productionMutations.setPage](state, pageNum: number) {
      state.page = pageNum;
    },
    [productionMutations.setProductionLoading](state, loading: boolean) {
      state.productionLoading = loading;
    },
    [productionMutations.setSelectedOperation](
      state,
      operation: ProdOperation
    ) {
      state.selectedOperation = operation;
    },
    [productionMutations.setSortBySubject](state, sortBySubject: boolean) {
      state.sortBySubject = sortBySubject;
    },
  },
  actions: {
    [productionActions.changeFilter]({ commit }, filter: string) {
      commit(productionMutations.setFilter, filter);
    },
    [productionActions.changePage]({ commit, state }, newPage: number) {
      if (newPage <= state.pages && newPage >= 1) {
        commit(productionMutations.setPage, newPage);
      }
    },
    [productionActions.changeSorting]({ commit }, sortBySubject: boolean) {
      commit(productionMutations.setSortBySubject, sortBySubject);
    },
    [productionActions.checkSelectedOperation]({ commit, state }) {
      commit(productionMutations.setProductionLoading, true);
      productionService
        .CheckOperation(state.selectedOperation)
        .then(async (operation) => {
          const dependencies = await storageService.GetItemsById(
            operation.DependencyIds
          );
          const results = await storageService.GetItemsById(
            operation.ResultIds
          );
          let orderIds = dependencies
            .map((e) => e.OrderId)
            .concat(results.map((e) => e.OrderId));
          orderIds = orderIds.filter((e) => !(!e || e == null || e === '')); //Remove empty
          orderIds = Array.from(new Set(orderIds)); //Remove duplicates
          let relevantOrders = new Array<Order>();
          if (orderIds.length > 0) {
            relevantOrders = await productionService.GetOrders(orderIds);
          }
          dependencies.forEach((d) => {
            if (!Array.isArray(relevantOrders) || !relevantOrders.length) {
              d.Order = new Order();
            } else {
              const orderIndex = relevantOrders.findIndex(
                (order) => order.Id.localeCompare(d.OrderId) === 0
              );
              if (orderIndex === -1) {
                d.Order = new Order();
              } else {
                d.Order = relevantOrders[orderIndex];
              }
            }
          });
          results.forEach((r) => {
            if (!Array.isArray(relevantOrders) || !relevantOrders.length) {
              r.Order = new Order();
            } else {
              const orderIndex = relevantOrders.findIndex(
                (order) => order.Id.localeCompare(r.OrderId) === 0
              );
              if (orderIndex === -1) {
                r.Order = new Order();
              } else {
                r.Order = relevantOrders[orderIndex];
              }
            }
          });
          operation.Dependencies = dependencies;
          operation.Results = results;
          commit(productionMutations.setSelectedOperation, operation);
          commit(productionMutations.setProductionLoading, false);
        })
        .catch((error) => {
          commit(productionMutations.setProductionLoading, false);
          throw error;
        });
    },
    [productionActions.fetchOperations](
      { commit, state, getters },
      listingDate: string
    ) {
      commit(productionMutations.setProductionLoading, true);
      if (!state.listingDate.includes(listingDate)) {
        commit(productionMutations.setListingDate, listingDate);
      }
      productionService
        .GetDateReadiness(listingDate)
        .then((value) => {
          commit(productionMutations.setDateReadiness, value);
        })
        .catch((error) => {
          console.warn('GetDateReadiness failed\n' + error);
        });
      productionService
        .GetProductionList(listingDate)
        .then((value) => {
          commit(productionMutations.setOperations, {
            operations: value,
            hasImport: getters.hasTerminalImport,
          });
          commit(productionMutations.setProductionLoading, false);
        })
        .catch((error) => {
          commit(productionMutations.setProductionLoading, false);
          throw error;
        });
    },
    [productionActions.finishOperation]({ commit, dispatch, state }) {
      commit(productionMutations.setProductionLoading, true);
      productionService
        .FinishOperation(state.selectedOperation.Id)
        .then(() => {
          dispatch(productionActions.checkSelectedOperation);
        })
        .catch((error) => {
          commit(productionMutations.setProductionLoading, false);
          throw error;
        });
    },
    [productionActions.reserveOperation]({ commit, dispatch, state }) {
      commit(productionMutations.setProductionLoading, true);
      productionService
        .ReserveOperation(state.selectedOperation.Id)
        .then(() => {
          dispatch(productionActions.checkSelectedOperation);
        })
        .catch((error) => {
          commit(productionMutations.setProductionLoading, false);
          throw error;
        });
    },
    [productionActions.selectOperation]({ commit }, operation: ProdOperation) {
      commit(productionMutations.setSelectedOperation, operation);
    },
  },
};

export default productionModule;
export { productionActions };
