import { Credentials, Terminal } from '@/models/terminal.model';
import axios, { AxiosInstance, RawAxiosRequestHeaders } from 'axios';

export class HttpService {
  protected static _baseURL: string;
  protected static _pathname: string;
  protected static _client: AxiosInstance;
  protected static _credentials: Credentials;
  protected static _accessToken: string;
  protected static _accessTokenTimerMinutes = 10;
  protected static _accessTokenTimer: number = Date.now();
  protected static _terminal: Terminal;

  public static isAuthenticated(): boolean {
    return (
      typeof HttpService._accessToken === 'string' &&
      HttpService._accessToken.length > 0
    );
  }

  constructor() {
    if (!HttpService._baseURL) {
      HttpService._baseURL = window.location.origin;
      if (HttpService._baseURL.includes(':8080')) {
        HttpService._baseURL = HttpService._baseURL.replace(':8080', ':9100');
      } else if (HttpService._baseURL.includes(':8081')) {
        HttpService._baseURL = HttpService._baseURL.replace(':8081', ':9100');
      } else {
        HttpService._baseURL += ':11001';
      }
      HttpService._client = axios.create({
        baseURL: HttpService._baseURL,
        withCredentials: false,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      console.log('HttpService._baseURL: ' + HttpService._baseURL);
    }
  }

  public setCredentials(credentials: Credentials): void {
    HttpService._credentials = credentials;
  }

  async authenticate(): Promise<Terminal> {
    const requestHeaders: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      TerminalId: HttpService._credentials.terminalIDU,
      Password: HttpService._credentials.terminalIDP,
    };
    const response = await HttpService._client.get('/Authentication/', {
      headers: requestHeaders,
      responseType: 'json',
    });
    HttpService._accessToken = response.headers['accesstoken'];
    const dataObject = JSON.parse(response.data);
    HttpService._terminal = dataObject.Terminal as Terminal;
    HttpService._accessTokenTimer =
      Date.now() + 6000 * HttpService._accessTokenTimerMinutes;
    HttpService.setCookie('TDTAccessToken', HttpService._accessToken, 1);
    HttpService.setCookie(
      'TDTTerminal',
      JSON.stringify(HttpService._terminal),
      1
    );
    return HttpService._terminal;
  }

  async refreshToken(): Promise<void> {
    const requestHeaders: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      TerminalId: HttpService._terminal.Id,
      Authorization: HttpService._accessToken,
    };
    const response = await HttpService._client.get('/Refresh/', {
      headers: requestHeaders,
      responseType: 'json',
    });
    HttpService._accessToken = response.headers['accesstoken'];
    const dataObject = JSON.parse(response.data);
    HttpService._terminal = dataObject.Terminal as Terminal;
    HttpService._accessTokenTimer =
      Date.now() + 6000 * HttpService._accessTokenTimerMinutes;
    HttpService.setCookie('TDTAccessToken', HttpService._accessToken, 1);
    HttpService.setCookie(
      'TDTTerminal',
      JSON.stringify(HttpService._terminal),
      1
    );
  }

  async get<T>(url: string, headers: [string, string][]): Promise<T> {
    if (!(await this.checkTokenAvailability())) {
      throw 'Access token unavailable';
    }
    const requestHeaders: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      Authorization: HttpService._accessToken,
    };
    if (headers) {
      headers.forEach((value) => (requestHeaders[value[0]] = value[1]));
    }
    const response = await HttpService._client.get(url, {
      headers: requestHeaders,
      responseType: 'json',
    });
    try {
      return JSON.parse(response.data) as T;
    } catch {
      return response.data;
    }
  }

  async put<T, U>(
    url: string,
    headers: [string, string][],
    data: U
  ): Promise<T> {
    if (!(await this.checkTokenAvailability())) {
      throw 'Access token unavailable';
    }
    const requestHeaders: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      Authorization: HttpService._accessToken,
    };
    if (headers) {
      headers.forEach((value) => (requestHeaders[value[0]] = value[1]));
    }
    const response = await HttpService._client.put(url, data, {
      headers: requestHeaders,
      responseType: 'json',
    });
    // console.log({ response: response });
    try {
      return JSON.parse(response.data) as T;
    } catch {
      return response.data;
    }
  }

  async delete<T>(url: string, headers: [string, string][]): Promise<T> {
    if (!(await this.checkTokenAvailability())) {
      throw 'Access token unavailable';
    }
    const requestHeaders: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      Authorization: HttpService._accessToken,
    };
    if (headers) {
      headers.forEach((value) => (requestHeaders[value[0]] = value[1]));
    }
    const response = await HttpService._client.delete<T>(url, {
      headers: requestHeaders,
      responseType: 'json',
    });
    return response.data;
  }

  public static getTerminalName(): string {
    return !HttpService._terminal ? '' : HttpService._terminal.Name;
  }

  public static getTerminalShortId(): string {
    return !HttpService._terminal ? '' : HttpService._terminal.ShortId;
  }

  public static getTerminalRole(): string {
    return !HttpService._terminal ? '' : HttpService._terminal.AuthorityId;
  }

  async getTerminalId(): Promise<string> {
    if (!HttpService._terminal) {
      return '';
    }
    return HttpService._terminal.Id;
  }

  public static isAdmin(): boolean {
    return this.getTerminalRole() === Terminal.Admin;
  }

  public static isTerminalId(id: string): boolean {
    return id === HttpService._terminal.Id;
  }

  async checkTokenAvailability(): Promise<boolean> {
    HttpService._accessToken = HttpService.getCookie('TDTAccessToken');
    const terminalString = HttpService.getCookie('TDTTerminal');
    if (terminalString) {
      HttpService._terminal = JSON.parse(terminalString);
      if (HttpService._accessTokenTimer < Date.now()) {
        try {
          await this.refreshToken();
        } catch (error) {
          console.error(error);
          return false;
        }
      }
      return true;
    }
    return false;
  }

  static getCookie(cname: string): string {
    //Source: https://www.w3schools.com/js/js_cookies.asp
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  static setCookie(cname: string, cvalue: string, exdays: number): void {
    //Source: https://www.w3schools.com/js/js_cookies.asp
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }
}
