import { Module } from 'vuex';
import { CustomerService } from '@/services/customer.service';
import { Customer } from '@/models/customer.model';
import { IRootState } from '@/store/rootstate';

export interface ICustomerModule {
  customers: Customer[];
  customersLoading: boolean;
}
const customerMutations = {
  setCustomers: 'setCustomers',
  setCustomersLoading: 'setCustomersLoading',
};
const customerActions = {
  fetchCustomers: 'fetchCustomers',
  removeCustomer: 'removeCustomer',
  upsertCustomer: 'upsertCustomer',
};

const cs = new CustomerService();
const customerModule: Module<ICustomerModule, IRootState> = {
  state: {
    customers: [] as Customer[],
    customersLoading: false as boolean,
  } as ICustomerModule,
  mutations: {
    [customerMutations.setCustomers](state, customers: Customer[]) {
      state.customers = customers.sort((a, b) =>
        a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase())
      );
    },
    [customerMutations.setCustomersLoading](state, isLoading: boolean) {
      state.customersLoading = isLoading;
    },
  },
  actions: {
    [customerActions.fetchCustomers]({ commit }) {
      commit(customerMutations.setCustomersLoading, true);
      cs.ListCustomers()
        .then((value) => {
          commit(customerMutations.setCustomers, value);
          commit(customerMutations.setCustomersLoading, false);
        })
        .catch((error) => {
          commit(customerMutations.setCustomersLoading, false);
          throw error;
        });
    },
    [customerActions.removeCustomer]({ dispatch, commit }, customer: Customer) {
      commit(customerMutations.setCustomersLoading, true);
      if (customer.Id) {
        cs.DeleteCustomer(customer.Id)
          .then(() => dispatch(customerActions.fetchCustomers))
          .catch((error) => {
            commit(customerMutations.setCustomersLoading, false);
            throw error;
          });
      }
    },
    [customerActions.upsertCustomer]({ dispatch, commit }, customer: Customer) {
      commit(customerMutations.setCustomersLoading, true);
      if (!customer.Id) {
        cs.AddCustomer(customer)
          .then(() => dispatch(customerActions.fetchCustomers))
          .catch((error) => {
            commit(customerMutations.setCustomersLoading, false);
            throw error;
          });
      } else {
        cs.UpdateCustomer(customer)
          .then(() => dispatch(customerActions.fetchCustomers))
          .catch((error) => {
            commit(customerMutations.setCustomersLoading, false);
            throw error;
          });
      }
    },
  },
};

export default customerModule;
export { customerActions };
