import { Module } from 'vuex';
import { StatisticsService } from '@/services/statistics.service';
import { MonthStatistics, StatisticsMember } from '@/models/statistics.model';
import { IRootState } from '@/store/rootstate';

export interface IStatisticsModule {
  date: string;
  orderStatistics: MonthStatistics[];
  statistics: {
    Today: StatisticsMember[];
    Daily: StatisticsMember[];
    Weekly: StatisticsMember[];
    Monthly: StatisticsMember[];
    ThisYear: StatisticsMember[];
  };
  statisticsOfDate: StatisticsMember[];
  statisticsLoading: boolean;
}
const statisticsMutations = {
  setOrderStatistics: 'setOrderStatistics',
  setStatistics: 'setStatistics',
  setStatisticsOfDate: 'setStatisticsOfDate',
  setStatisticsLoading: 'setStatisticsLoading',
};
const statisticsActions = {
  fetchOrderStatistics: 'fetchOrderStatistics',
  fetchStatistics: 'fetchStatistics',
  fetchStatisticsOfDate: 'fetchStatisticsOfDate',
};

const cs = new StatisticsService();
const statisticsModule: Module<IStatisticsModule, IRootState> = {
  state: {
    date: '',
    orderStatistics: [],
    statistics: {
      Today: [],
      Daily: [],
      Weekly: [],
      Monthly: [],
      ThisYear: [],
    },
    statisticsOfDate: [],
    statisticsLoading: false,
  } as IStatisticsModule,
  mutations: {
    [statisticsMutations.setOrderStatistics](state, reply: MonthStatistics[]) {
      state.orderStatistics = reply;
    },
    [statisticsMutations.setStatistics](
      state,
      reply: {
        Today: StatisticsMember[];
        Daily: StatisticsMember[];
        Weekly: StatisticsMember[];
        Monthly: StatisticsMember[];
        ThisYear: StatisticsMember[];
      }
    ) {
      state.statistics = reply;
    },
    [statisticsMutations.setStatisticsOfDate](
      state,
      reply: StatisticsMember[]
    ) {
      state.statisticsOfDate = reply;
    },
    [statisticsMutations.setStatisticsLoading](state, isLoading: boolean) {
      state.statisticsLoading = isLoading;
    },
  },
  actions: {
    [statisticsActions.fetchOrderStatistics]({ commit }, filter: string) {
      commit(statisticsMutations.setStatisticsLoading, true);
      cs.GetOrderStatistics(filter)
        .then((value) => {
          commit(statisticsMutations.setOrderStatistics, value);
          commit(statisticsMutations.setStatisticsLoading, false);
        })
        .catch((error) => {
          commit(statisticsMutations.setStatisticsLoading, false);
          throw error;
        });
    },
    [statisticsActions.fetchStatistics]({ commit }) {
      commit(statisticsMutations.setStatisticsLoading, true);
      cs.GetStatistics()
        .then((value) => {
          commit(statisticsMutations.setStatistics, value);
          commit(statisticsMutations.setStatisticsLoading, false);
        })
        .catch((error) => {
          commit(statisticsMutations.setStatisticsLoading, false);
          throw error;
        });
    },
    [statisticsActions.fetchStatisticsOfDate]({ commit }, date: string) {
      commit(statisticsMutations.setStatisticsLoading, true);
      cs.GetStatisticsOfDate(date)
        .then((value) => {
          commit(statisticsMutations.setStatisticsOfDate, value);
          commit(statisticsMutations.setStatisticsLoading, false);
        })
        .catch((error) => {
          commit(statisticsMutations.setStatisticsLoading, false);
          throw error;
        });
    },
  },
};

export default statisticsModule;
export { statisticsActions };
