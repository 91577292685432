import { Module } from 'vuex';
import {
  ShiftStatus,
  Order,
  OrderAux,
  OrderReadiness,
} from '@/models/order.model';
import { OrderService } from '@/services/order.service';
import { IRootState } from '@/store/rootstate';

export interface OrdersSelected {
  customer: string;
  date: string;
}

export interface IOrderModule {
  shifts: ShiftStatus[];
  shiftsLoading: boolean;
  orderAuxiliary: OrderAux;
  orderAuxiliaryLoading: boolean;
  orders: Order[];
  ordersReadiness: OrderReadiness[];
  ordersSelected: OrdersSelected;
  ordersLoading: boolean;
}
const orderMutations = {
  setShifts: 'setShifts',
  setShiftsLoading: 'setShiftsLoading',
  setOrderAux: 'setOrderAux',
  setOrderAuxLoading: 'setOrderAuxLoading',
  setOrders: 'setOrders',
  setOrdersSelected: 'setOrdersSelected',
  setOrdersLoading: 'setOrdersLoading',
};
const orderActions = {
  fetchShifts: 'fetchShifts',
  fetchOrderAux: 'fetchOrderAux',
  fetchOrders: 'fetchOrders',
  removeOrder: 'removeOrder',
  removeMultipleOrder: 'removeMultipleOrder',
  upsertOrder: 'upsertOrder',
  upsertMultipleOrder: 'upsertMultipleOrder',
  lockShift: 'lockShift',
};

const orderService = new OrderService();
const orderModule: Module<IOrderModule, IRootState> = {
  state: {
    shifts: [],
    shiftsLoading: false,
    orderAuxiliary: new OrderAux(),
    orderAuxiliaryLoading: false as boolean,
    orders: [],
    ordersReadiness: [],
    ordersSelected: {} as OrdersSelected,
    ordersLoading: false as boolean,
  } as IOrderModule,
  mutations: {
    [orderMutations.setShifts](state, shiftstatusArray: ShiftStatus[]) {
      state.shifts = shiftstatusArray;
    },
    [orderMutations.setShiftsLoading](state, isLoading: boolean) {
      state.shiftsLoading = isLoading;
    },
    [orderMutations.setOrderAux](state, orderAux: OrderAux) {
      state.orderAuxiliary = orderAux;
    },
    [orderMutations.setOrderAuxLoading](state, isLoading: boolean) {
      state.orderAuxiliaryLoading = isLoading;
    },
    [orderMutations.setOrders](
      state,
      params: { orders: Order[]; readiness: OrderReadiness[] }
    ) {
      state.orders = params.orders;
      state.ordersReadiness = params.readiness;
    },
    [orderMutations.setOrdersSelected](state, ordersSelected: OrdersSelected) {
      state.ordersSelected = ordersSelected;
    },
    [orderMutations.setOrdersLoading](state, isLoading: boolean) {
      state.ordersLoading = isLoading;
    },
  },
  actions: {
    [orderActions.fetchShifts](
      { commit },
      param: { year: number; month: number }
    ) {
      orderService
        .GetShift(param.year, param.month)
        .then((value) => {
          commit(orderMutations.setShifts, value);
          commit(orderMutations.setShiftsLoading, false);
        })
        .catch((error) => {
          commit(orderMutations.setShiftsLoading, false);
          throw error;
        });
    },
    [orderActions.fetchOrderAux]({ commit }) {
      commit(orderMutations.setOrderAuxLoading, true);
      orderService
        .GetAux()
        .then((value) => {
          commit(orderMutations.setOrderAux, value);
          commit(orderMutations.setOrderAuxLoading, false);
        })
        .catch((error) => {
          commit(orderMutations.setOrderAuxLoading, false);
          throw error;
        });
    },
    [orderActions.fetchOrders]({ commit, state }, param: OrdersSelected) {
      if (!param) {
        param = (state as IOrderModule).ordersSelected;
      } else {
        commit(orderMutations.setOrdersSelected, param);
      }
      commit(orderMutations.setOrdersLoading, true);
      if (!param.customer) {
        orderService
          .GetOrdersOfShift(param.date)
          .then((orders) => {
            orderService
              .GetOrdersReadiness(orders.map((o) => o.Id))
              .then((readiness) => {
                commit(orderMutations.setOrders, { orders, readiness });
                commit(orderMutations.setOrdersLoading, false);
              })
              .catch((error) => {
                commit(orderMutations.setOrdersLoading, false);
                throw error;
              });
          })
          .catch((error) => {
            commit(orderMutations.setOrdersLoading, false);
            throw error;
          });
      } else {
        orderService
          .GetOrdersOfCustomer(param.customer, param.date)
          .then((orders) => {
            orderService
              .GetOrdersReadiness(orders.map((o) => o.Id))
              .then((readiness) => {
                commit(orderMutations.setOrders, { orders, readiness });
                commit(orderMutations.setOrdersLoading, false);
              })
              .catch((error) => {
                commit(orderMutations.setOrdersLoading, false);
                throw error;
              });
          })
          .catch((error) => {
            commit(orderMutations.setOrdersLoading, false);
            throw error;
          });
      }
    },
    [orderActions.removeOrder]({ dispatch, commit }, order: Order) {
      commit(orderMutations.setOrdersLoading, true);
      if (order.Id) {
        orderService
          .DeleteOrder(order.Id)
          .then(() => dispatch(orderActions.fetchOrders))
          .catch((error) => {
            commit(orderMutations.setOrdersLoading, false);
            throw error;
          });
      }
    },
    [orderActions.removeMultipleOrder]({ dispatch, commit }, orders: Order[]) {
      commit(orderMutations.setShiftsLoading, true);
      const targetDate = new Date(orders[0].ProductionDate);
      Promise.all(
        orders.map((order) => {
          orderService.DeleteOrder(order.Id);
        })
      )
        .then(() =>
          dispatch(orderActions.fetchShifts, {
            year: targetDate.getUTCFullYear(),
            month: targetDate.getUTCMonth() + 1,
          })
        )
        .catch((error) => {
          commit(orderMutations.setShiftsLoading, false);
          throw error;
        });
    },
    [orderActions.upsertOrder]({ dispatch, commit }, order: Order) {
      commit(orderMutations.setOrdersLoading, true);
      if (!order.Id) {
        orderService
          .AddOrder(order)
          .then(() => dispatch(orderActions.fetchOrders))
          .catch((error) => {
            commit(orderMutations.setOrdersLoading, false);
            throw error;
          });
      } else {
        orderService
          .UpdateOrder(order)
          .then(() => dispatch(orderActions.fetchOrders))
          .catch((error) => {
            commit(orderMutations.setOrdersLoading, false);
            throw error;
          });
      }
    },
    [orderActions.upsertMultipleOrder]({ dispatch, commit }, orders: Order[]) {
      commit(orderMutations.setShiftsLoading, true);
      const targetDate = new Date(orders[0].ProductionDate);
      const processOrder = async (order: Order) => {
        if (!order.Id) {
          await orderService.AddOrder(order);
        } else {
          await orderService.UpdateOrder(order);
        }
      };
      (async () => {
        for (const order of orders) {
          await processOrder(order);
        }
      })()
        .then(() => {
          dispatch(orderActions.fetchShifts, {
            year: targetDate.getUTCFullYear(),
            month: targetDate.getUTCMonth() + 1,
          });
          dispatch(orderActions.fetchOrders);
        })
        .catch((error) => {
          commit(orderMutations.setShiftsLoading, false);
          throw error;
        });
    },
    [orderActions.lockShift](
      { dispatch, commit },
      target: { shift: string; lockStatus: boolean }
    ) {
      commit(orderMutations.setShiftsLoading, true);
      const targetDate = new Date(target.shift);
      orderService
        .LockDate(target)
        .then(() =>
          dispatch(orderActions.fetchShifts, {
            year: targetDate.getUTCFullYear(),
            month: targetDate.getUTCMonth() + 1,
          })
        )
        .catch((error) => {
          commit(orderMutations.setShiftsLoading, false);
          throw error;
        });
    },
  },
};

export default orderModule;
export { orderActions };
